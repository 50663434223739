.beconImage{
    max-width: 1100px;
    max-height: 900px;
}

.btn-location{
    border: 1px solid black;
    border-radius: 4px;
    padding: 9px;
    padding-right: 24px;
    margin-left: 15px;
    margin-right: 15px;

    width: 15px;
    height: 38px;
    color: black;

  }

  /* Modal Overlay: White with transparency */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.9); /* Transparent white */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensures it is on top */
    overflow: hidden;
  }
  
  /* Modal Content: Centered and clean */
  .modal-content {
    position: relative;
    width: 90%;
    max-width: 1200px;
    height: 90%;
    background: transparent; /* Transparent background */
    outline: none;
    border: none;
  }
  
  /* Image carousel container */
  .image-carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  
  /* Slick slide fix: Centers the images */
  .slick-slide > div {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  
  /* Image Scaling */
  .image-carousel-container img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  }
  